import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/pages/home.vue";
import AccessGuide from "@/pages/accessGuide.vue";
import BindECS from "@/pages/bindECS.vue";
import AppTemplate from "@/pages/appTemplate.vue";
import MyApp from "@/pages/myApp.vue";
import AkManage from "@/pages/akManage.vue";
import newsRouter from "./news";
import productRouter from "./product";
import customerRouter from "./customer";
import pictureRouter from "./picture";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "default",
    component: Home,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/accessGuide",
    name: "accessGuide",
    component: AccessGuide,
  },
  {
    path: "/bindECS",
    name: "bindECS",
    component: BindECS,
  },
  {
    path: "/appTemplate",
    name: "appTemplate",
    component: AppTemplate,
  },
  {
    path: "/myApp",
    name: "myApp",
    component: MyApp,
  },
  {
    path: "/akManage",
    name: "akManage",
    component: AkManage,
  },
  ...newsRouter,
  ...productRouter,
  ...customerRouter,
  ...pictureRouter,
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
