<template>
  <el-container class="contentWrapper">
    <el-header height="80px" style="border: 1px solid #eceef3">
      <Header />
    </el-header>
    <el-container style="height: calc(100% - 80px)">
      <el-aside class="contentLeft" width="400px">
        <div class="formTitle">
          输入参数
          <div>
            <el-switch v-model="isCompleteMessage" @change="switchChange">
            </el-switch>
            <span class="switchText">完整报文</span>
          </div>
        </div>
        <el-scrollbar class="formScroll">
          <el-form
            class="formWrapper"
            v-show="!isCompleteMessage"
            :model="formData"
            ref="argumentForm"
            :rules="rules"
            label-position="top"
          >
            <el-form-item prop="Id" label="Id 产品Id">
              <el-input
                v-model.number="formData.Id"
                placeholder="请输入整型数字"
              ></el-input>
            </el-form-item>
            <el-form-item prop="Name" label="Name 产品标题">
              <el-input
                v-model="formData.Name"
                placeholder="请输入字符串"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="completeWrapper" v-show="isCompleteMessage">
            <div class="completeTitle">ProductModel</div>
            <el-input
              v-model="completeformData"
              type="textarea"
              rows="20"
              placeholder="请输入参数对象"
            ></el-input>
          </div>
        </el-scrollbar>
        <div class="formFooter">
          <el-button @click="resetForm">清空</el-button>
          <el-button type="primary" @click="submitForm">发起调用</el-button>
        </div>
      </el-aside>
      <div class="tabsWrapper">
        <el-tabs v-model="tabsActive" @tab-click="tabsChange">
          <el-tab-pane name="document" label="文档">
            <el-scrollbar style="height: 100%">
              <RequestMethod method="POST" address="/OpenProduct/Update" />
              <div class="tableTitle">请求参数</div>
              <el-table
                :data="requestTableData"
                style="width: 100%"
                default-expand-all
                :border="true"
                row-key="name"
              >
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                </el-table-column>
                <el-table-column prop="isRequired" label="必填" width="100">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
              <div class="tableTitle">响应参数</div>
              <el-table
                class="lastTable"
                :data="responseTableData"
                style="width: 100%"
                default-expand-all
                :border="true"
                row-key="name"
              >
                <el-table-column prop="name" label="名称"> </el-table-column>
                <el-table-column prop="type" label="类型" width="100">
                </el-table-column>
                <el-table-column prop="description" label="描述">
                </el-table-column>
                <el-table-column prop="example" label="示例值">
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="result" label="调用结果">
            <el-scrollbar style="height: 100%">
              <div class="hljs-github">
                <pre
                  v-show="responseData"
                  v-highlight
                  :key="timestep"
                ><code class="json" >{{ responseData }}</code></pre>
              </div>
              <div v-show="!responseData">
                <el-empty description="暂无调用结果"></el-empty>
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane name="history" label="调用历史">
            <History ref="history" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import { productUpdate, getProductDetail } from "@/api/index.js";
import Header from "@/components/header.vue";
import History from "@/components/history.vue";
import RequestMethod from "@/components/requestMethod.vue";
export default {
  components: {
    Header,
    History,
    RequestMethod,
  },
  data() {
    return {
      isCompleteMessage: false,
      formData: {
        Id: "",
        Name: "",
      },
      completeformData: `{
    "Id": "",
    "Name": "",
    "IsExsitOrder": null,
    "RichTextTemplateId": 0,
    "Released": true,
    "SetTopTime": null,
    "ReleasedTime": null,
    "SetTop": false,
    "IsAllowComments": true,
    "ShortDescription": null,
    "FullDescription": null,
    "SearchKeywords": null,
    "MetaKeywords": null,
    "SEOFriendlyName": null,
    "MetaDescription": null,
    "ckbIsAllowComments": "on",
    "SubjectToAcl": false,
    "NeedShipping": true,
    "UseSkuPriceSystem": false,
    "Price": 0,
    "OldPrice": 0,
    "ProductCost": 0,
  }`,
      defaultArgument: {},
      rules: {
        Id: [
          { required: true, message: "请输入产品Id", trigger: "blur" },
          { type: "number", message: "参数必须为整型数字" },
        ],
        Name: [{ required: true, message: "请输入Name", trigger: "blur" }],
      },
      tabsActive: "document",
      requestTableData: [
        {
          name: "ProductModel",
          type: "",
          isRequired: "",
          description: "",
          example: "",
          children: [
            {
              name: "Id",
              type: "Integer",
              isRequired: "是",
              description: "产品Id",
              example: "",
            },
            {
              name: "Name",
              type: "String",
              isRequired: "是",
              description: "产品标题",
              example: "",
            },
            {
              name: "ProductCategoryIds",
              type: "Object []",
              isRequired: "否",
              description: "产品所属分类Id数组",
              example: "",
            },

            {
              name: "ShortDescription",
              type: "String",
              isRequired: "否",
              description: "产品简介",
              example: "",
            },
            {
              name: "FullDescription",
              type: "String",
              isRequired: "否",
              description: "产品内容详情",
              example: "",
            },
            {
              name: "Released",
              type: "Boolean",
              isRequired: "否",
              description: "产品是否发布",
              example: "",
            },
            {
              name: "VisitCount",
              type: "String",
              isRequired: "否",
              description: "产品访问量",
              example: "",
            },
            {
              name: "SetTop",
              type: "Boolean",
              isRequired: "否",
              description: "产品是否置顶",
              example: "",
            },
            {
              name: "Deleted",
              type: "Boolean",
              isRequired: "否",
              description: "产品是否删除",
              example: "",
            },
            {
              name: "Price",
              type: "String",
              isRequired: "否",
              description: "产品现价",
              example: "",
            },
            {
              name: "OldPrice",
              type: "String",
              isRequired: "否",
              description: "产品原价",
              example: "",
            },
            {
              name: "ProductCost",
              type: "String",
              isRequired: "否",
              description: "产品成本价",
              example: "",
            },
            {
              name: "primaryImg",
              type: "String",
              isRequired: "否",
              description: "产品封面图片",
              example: "",
            },
            {
              name: "Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9,Img10",
              type: "String",
              isRequired: "否",
              description: "产品图片",
              example: "",
            },
            {
              name: "ProductPictureModels",
              type: "Object []",
              isRequired: "否",
              description: "产品图片数据数组",
              example: "",
            },
            {
              name: "IsAllowComments",
              type: "Boolean",
              isRequired: "否",
              description: "产品是否允许评论",
              example: "",
            },
            {
              name: "SubjectToAcl",
              type: "Boolean",
              isRequired: "否",
              description: "产品访问权限是否开启",
              example: "",
            },
            {
              name: "SelectedCustomerRoleIds",
              type: "Object []",
              isRequired: "否",
              description: "产品访问权限选中id数组",
              example: "",
            },
            {
              name: "MetaKeywords",
              type: "String",
              isRequired: "否",
              description: "产品SEO关键词",
              example: "",
            },
            {
              name: "MetaDescription",
              type: "String",
              isRequired: "否",
              description: "产品SEO描述",
              example: "",
            },
            {
              name: "SearchKeywords",
              type: "String",
              isRequired: "否",
              description: "产品搜索关键词",
              example: "",
            },
            {
              name: "RichTextTemplateId",
              type: "Integer",
              isRequired: "否",
              description: "富文本模版Id",
              example: "",
            },
          ],
        },
      ],
      responseTableData: [
        {
          name: "Data",
          type: "Integer",
          description: "产品Id",
          example: "",
        },
        {
          name: "IsSuccess",
          type: "Boolean",
          description: "请求是否成功",
          example: "true",
        },
        {
          name: "Msg",
          type: "String",
          description: "请求失败信息",
          example: "",
        },
        {
          name: "RequestId",
          type: "String",
          description: "请求ID",
          example: "",
        },
      ],
      responseData: "",
      timestep: "",
    };
  },
  methods: {
    switchChange() {
      if (this.isCompleteMessage) {
        let completeformDataObj = new Function(
          "return" + this.completeformData
        )();
        if (this.formData.Id) {
          getProductDetail({ Id: this.formData.Id })
            .then((res) => {
              if (res.IsSuccess) {
                if (res.Data) {
                  completeformDataObj = res.Data;
                }
                if (this.formData.Name) {
                  completeformDataObj.Name = this.formData.Name;
                }
                this.completeformData = JSON.stringify(
                  completeformDataObj,
                  null,
                  2
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (this.formData.Name) {
          completeformDataObj.Name = this.formData.Name;
        }
        this.completeformData = JSON.stringify(completeformDataObj, null, 2);
      }
    },
    submitForm() {
      if (this.isCompleteMessage) {
        let completeformDataObj = new Function(
          "return" + this.completeformData
        )();
        productUpdate(completeformDataObj)
          .then((res) => {
            this.tabsActive = "result";
            this.timestep = Date.parse(new Date());
            this.responseData = res;
            if (res.IsSuccess) {
              this.$message({
                message: "调用成功",
                type: "success",
              });
            } else {
              this.$message.error(res.Msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$refs["argumentForm"].validate((valid) => {
          if (valid) {
            getProductDetail({ Id: this.formData.Id })
              .then((res) => {
                if (res.IsSuccess) {
                  this.defaultArgument = res.Data;
                  let data = {
                    ...this.defaultArgument,
                    ...this.formData,
                  };
                  productUpdate(data)
                    .then((res) => {
                      this.tabsActive = "result";
                      this.timestep = Date.parse(new Date());
                      this.responseData = res;
                      if (res.IsSuccess) {
                        this.$message({
                          message: "调用成功",
                          type: "success",
                        });
                      } else {
                        this.$message.error(res.Msg);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  this.$message.error(res.Msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    resetForm() {
      this.$refs["argumentForm"].resetFields();
    },
    tabsChange() {
      if (this.tabsActive == "history") {
        this.$refs["history"].getApiHistory();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less"></style>
